

$text-light: #e2eeff;
$backdrop-light: #3a3aaf;
$component-dark: #0f5b9c;
$headingcolor:#0f5b9c;
$hovercolor:#0f5b9c;
$buttoncolor:#5271ff;
$maincontainercolor:#ffff;



.maincontainer {
    background-color: $maincontainercolor;
    text-align: justify;
    height: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 20%);
    object-fit: contain;
}


@media screen and (max-width: 768px) {
    .maincontainer{
        background-color: $maincontainercolor;
        text-align: justify;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 20%);
        object-fit: contain;
    }
} 

.button {
    width: 100%;
    margin-top: 20px;
    background-color: $buttoncolor;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.button:hover{
    background-color: $hovercolor;
    padding: 17px 35px;
}
.cGKbZh :hover{
    background-color: green !important;
}
.headercolor{
  color:$headingcolor !important;
}
h2{
    font-weight: 900
}

.ehkxHv{
    color: rgb(0, 0, 0) !important;
}
.ejXxNI {
    box-sizing: border-box;
    padding: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    background-color: $buttoncolor !important;
    font-size: 1.3em;
    content: "";
    height: 10px;
    width: 10px;
    box-shadow: 0 0 1px 3px $hovercolor !important;
    border-radius: 50%;
    outline: none;
}
.card {
    background:$backdrop-light;
    box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    border: 0;
    border-radius: 1rem
}
.card-img,
.card-img-top {
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px)
}
.card h5 {
    overflow: hidden;
    height: 50px;
    font-weight: 900;
    font-size: 1rem
}
.card-img-top {
    width: 100%;
    max-height: 240px;
    object-fit: contain;
    padding: 30px
}
.card h2 {
    font-size: 1rem
}
.card:hover {
    background-color:$hovercolor !important;
}
.cGKbZh {
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1.6em;
    background-color: rgba(103,58,183,0.1);
    color: #333;
    box-shadow: 0 0 2px 0px #333;
    border-radius: 50%;
    border: none;
    padding: 0;
    width: 50px;
    height: 50px;
    min-width: 50px;
    line-height: 50px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
}
.cGKbZh:hover:enabled, .cGKbZh:focus:enabled{
    background-color: $hovercolor !important;
}
.label-top {
    position: absolute;
    background-color: #8bc34a;
    color: #fff;
    top: 8px;
    right: 8px;
    padding: 5px 10px 5px 10px;
    font-size: .7rem;
    font-weight: 600;
    border-radius: 3px;
    text-transform: uppercase
}
.over-bg {
    background: rgba(53, 53, 53, 0.85);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(0.0px);
    -webkit-backdrop-filter: blur(0.0px);
    border-radius: 10px
}
.btn {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px 50px 5px 50px
}
.box .btn {
    font-size: 1.5rem
}
footer {
    background: #212529;
    height: 80px;
    color: #fff
}
body {
    background-color: #eee
}
.card {
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    color: white;
}
.c-details span {
    font-weight: 300;
    font-size: 13px
}
.icon {
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 39px
}
.badge span {
    background-color: #fffbec;
    width: 60px;
    height: 25px;
    padding-bottom: 3px;
    border-radius: 5px;
    display: flex;
    color: #fed85d;
    justify-content: center;
    align-items: center
}
.text1 {
    font-size: 14px;
    font-weight: 600
}
.text2 {
    color: #a5aec0
}


.p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
}
.h3edit {
    font-size: 4rem !important;
    margin-bottom: 15px !important;
    display: flex;
    text-align: left;
    font-weight: bold;
}

.missionEdit{
    
}

.h5edit{
    display: flex;
    text-align: flex;
    margin-bottom: 1px;
}

.Team{
    font-size: 2.75rem;
    margin-bottom: 15px;
    display: flex;
    text-align:left;

}
.getinv{
    margin-left: 32px;
    font-size: 2.75rem!important;

}
.getinv1 {
    margin-left: 48px;
    font-size: 2.75rem !important;
    display: flex;
}
.video{
    width: 100%;
}  
.getinv2 {
    margin-left: 13px;
    font-size: 2.75rem !important;
    margin-left: 0px!important;
    display: flex;
    padding-left: 7px;
}
.subcatageroy{
    padding: 100px;
    background-color: lightgray;
    text-align: center;
}
.rowedit{
    margin: 15px;
}
.missionPic{
    max-width: 100%;
    height: auto;
    
}
.visionPic{
    max-width: 100%;
    height: auto;
}


.programmePic{
   width: 100%;
   height: auto;
}

@media screen and (max-width: 768px) {
    .default-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
    .card-img-top {
        max-height: 250px
    }
    .video{
        width: 100%;
    }  
    .default-container > p {
      font-size: 30px;
    }
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
    .btn {
      width: 100%;
    }
    .p{
        font-size: 12px;
        text-align: justify!important;
    }
    .getinv1 {
        margin-left: 48px;
        font-size: 2.75rem !important;
        display: flex;
    }
    .getinv2 {
        margin-left: 13px;
        font-size: 2.75rem !important;
        margin-left: 0px!important;
        display: flex;
        padding-left: 7px;
    }
    .ejXxNI {
        box-sizing: border-box;
        padding: 0;
        -webkit-transition: all 250ms ease;
        transition: all 250ms ease;
        border: none;
        margin: 5px;
        background-color: $buttoncolor !important;
        font-size: 1.3em;
        content: "";
        height: 10px;
        width: 10px;
        box-shadow: 0 0 1px 3px $hovercolor !important;
        border-radius: 50%;
        outline: none;
    }


    .h3edit {
        font-size: 2.75rem !important;
        margin-bottom: 15px !important;
        display: flex;
        text-align: left;
    }
    .rowedit{
        margin: 20px;
        
    }

    .row-cols-1>* {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 18px;
    }
    .mission{
        width: 100%;
    }
}
@media screen and (max-width: 1024px) {
    .video{
        width: 90%;
    }  
}

























