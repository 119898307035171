.main-footer {
    color: rgb(255, 255, 255);
    padding-top: 3em;
    position: relative;
    bottom: 0;
    width: 100%;
    box-shadow: inset 0 0 0 1000px #3a3aaf;
    font-size: 10pt;
  }

  .list-unstyled{
    font-size: 12.5pt;
    color: cadetblue;
  }

  .list-unstyled-2{
    list-style-type: none;
    color: coral;
  }

  .list-unstyled-social{
    list-style-type: none;
    font-size: x-large;
  }